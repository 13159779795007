/* eslint no-param-reassign: ["error", { "props": true, "ignorePropertyModificationsFor": ["variables"] }] */
/* eslint no-underscore-dangle: ["error", { "allow": ["_meta"] }] */
import { React } from '@biletiniz/ui-core';
import { NextSeo, FAQPageJsonLd } from '@biletiniz/seo';
import { Layout } from '@biletiniz/ui-layout';
import { HomePage } from '@biletiniz/ui-home-template';
import Router from 'next/router';
import { qs } from '@biletiniz/shared';
import {
  FlightSearchFormContainer,
  KeyWordsCards,
} from '@biletiniz/web-app-containers';
import { useTheme } from '@biletiniz/ui-style';
import Head from 'next/head';
import { useRouter, useHotBlogs } from '../src/hooks';

const Index = ({ hotBlogs }) => {
  const { flightOffersSearchForm, router } = useRouter();
  const theme = useTheme();

  const pt = theme.space[5] - 4;
  const spt = theme.space[5] - 15;

  return (
    <>
      <NextSeo
        title="En Ucuz Otobüs ve Uçak Bileti | Biletiniz.com | Vize Danışmanlığı"
        description="Biletiniz.com ile en uygun otobüs ve uçak biletleri bulmanın yanı sıra vize başvurusu ve danışmanlık hizmetlerinden de faydalanabilirsiniz. Uygun fiyatlar, kolay rezervasyon!"
        canonical={`https://biletiniz.com${
          router.locale !== 'tr' ? '/en' : ''
        }`}
      />
      <Head>
        <meta
          name="keywords"
          content="Otobüs Bileti, Uçak Bileti, En Ucuz Bilet, En Uygun Bilet, Ucuz Otobüs Bileti, Uygun Otobüs Bileti, Ucuz Uçak Bileti, Uygun Uçak Bileti, Vize Başvurusu, Vize Danışmanlığı"
        />
        <meta
          name="facebook-domain-verification"
          content="thjgnx9fmlkqf4k5q3z03kepoopgq8"
        />
      </Head>
      <FAQPageJsonLd
        mainEntity={[
          {
            questionName: 'Ucuz Uçak Bileti Nasıl Alınır?',
            acceptedAnswerText:
              'Hesaplı seyahat için erken rezervasyon yaptırın, gideceğiniz tarihler netleştiği anda uçak bileti alın. Zorunlu değilseniz seyahatinizi bayram, yılbaşı ve özel günlerden uzak tutmaya çalışın. İzin alabiliyorsanız Perşembe seyahat genelde Cuma akşam seyahatten daha ucuz olacaktır. Uçağın uçuş saati de bilet fiyatlarında etkili.',
          },
          {
            questionName: 'Uçak Bileti Rezervasyonu Nasıl Yapılır?',
            acceptedAnswerText:
              'Web sitemizi kullanarak rezervasyon oluşturabilir ve size otomatik olarak verilen opsiyona ya da daha sonra tarafınıza bildirilen yeni bir opsiyona kadar biletinizi satın alabilirsiniz.İşlem sırasında tarafınıza verilen opsiyon, rezervasyonun oluşması için sistem tarafından verilmiş olup, havayolları sistemin vermiş olduğu opsiyonu öne çekme hakkına sahiptir.Bu durumda yeni opsiyon süreniz Biletiniz’e vermiş olduğunuz iletişim bilgilerine göre yazılı ve/veya sözlü olarak tarafınıza bildirilecek ve yeni opsiyon sürenizi dikkate almanız istenecektir.',
          },
          {
            questionName: 'Uçak Bileti Nasıl İptal Edilir?',
            acceptedAnswerText:
              'Bilet iptal ve iadesi için ana sayfada bulunan Bilet İptal bölümünden bilgilerinizi girerek iptal edilebilen biletlerde işlemlerinizi gerçekleştirebilirsiniz. Dilerseniz 0850 203 3203 numaralı Çağrı Merkezimizi arayarak da işlem yaptırabilirsiniz.',
          },
          {
            questionName: 'İnternetten Uçak Bileti Nasıl Alınır?',
            acceptedAnswerText:
              'Ana sayfada bulunan arama ekranından tek yön ya da gidiş-dönüş seçenekleri arasından seçiminizi yapın. Sonrasında havaalanı, uçuş tarihi, kişi sayısı bilgilerini girerek uçuşları aramaya başlayabilirsiniz. Size en uygun seferi belirleyip seçtikten sonra ilgili uçuşun üzerine tıklayarak bilgilerinizi gireceğiniz yolcu bilgileri ekranına yönlendirileceksiniz. Bu ekranda yolculara ait AD SOYAD, DOĞUM TARİHİ TC KİMLİK NO ve İLETİŞİM BİLGİLERİ alanlarını doldurmalısınız. SATIN AL butonuna tıklayarak kullanım koşulları, uçuş kuralları ve gizlilik politikasını onayladıktan sonra yönlendirileceğiniz 3D Secure sistemiyle güvenli bir şekilde kredi kartı ya da bankamatik kartınızla bilet alım işleminizi tamamlayabilirsiniz.',
          },
          {
            questionName: 'Son Dakika Uçak Bileti Nasıl Alınır?',
            acceptedAnswerText:
              'Bazı zamanlarda bilet rezervasyonlarında iptaller olabilir ve o biletler tekrar satışa açılır ya da uçakta doluluk oranı, günler yaklaştıkca yeterli sayıda değil ise havayolu firmaları fiyatlarını düşürürler.Biletiniz’le iletişime geçerek veya sayfada bulunan online bilet ara sekmesinden de son dakika biletlerinizi alabilirsiniz.',
          },
          {
            questionName: 'Uçak Bileti Sınıfları Kodları Ne Demek?',
            acceptedAnswerText:
              'Promosyon sınıfına dahil olan biletlerde herhangi bir İptal, iade ve değişiklik yapılamaz.  Ekonomi kategorisinde iptal, iade ve değişiklik koşulları havayolunun kurallarına bağlı olarak değişiklik gösterebilir.  Business kategorisine ait biletlerde de iptal, iade ve değişiklik koşulları seçmiş olduğunuz havayolunun kuralına göre değişiklik gösterebilir.',
          },
          {
            questionName: 'Uçak Bileti Check In Nasıl Yapılır?',
            acceptedAnswerText:
              'Online check-in yapmadan önce biletinizi almış olmanız gerekiyor. Online check-in hizmeti uçuşunuza 24 veya 72 saat kala başlıyor ve son 45 veya 60 dakikaya kadar yapılabiliyor. Online check-in yapmak için internetten biletinizi aldığınız havayolunun web sitesine giriyor ve “online check-in” sekmesini tıklıyorsunuz. Açılan kutuya PNR kodunuzu(rezervasyon numarası) ve ad-soyad bilgilerinizi giriyorsunuz. Şirket koltuk seçimine izin veriyorsa koltuğunuzu seçip işleminizi tamamlıyorsunuz. Bazı şirketler için yemek seçimi ve ek bagaj hakkı gibi işlemlerinizi de bu aşamada tamamlayabilirsiniz.Online check-in yaptıktan sonra e-mail ve SMS ile gelen barkodu güvenlik geçişlerinde uçuş biniş kartı olarak kullanabilirsiniz. Basılı olarak kullanabileceğiniz gibi cep telefonunuzdan da okutabilirsiniz.',
          },
          {
            questionName: 'Açığa Alınan Uçak Bileti Nasıl Kullanılır?',
            acceptedAnswerText:
              'Açık biletlerin üzerinde bulunan PNR kodu ile biletlerini kullanabilmektedirler. Firmadan firmaya değişmekte olan açık bilet kullanım süresi ise ortalama 1 senedir. Bu süre içerisinde kullanmamanız dahilinde ise açığa almış olduğunuz biletleriniz iptal edilir.',
          },
          {
            questionName: 'Uçak Bileti Ne Zaman Alınmalı?',
            acceptedAnswerText:
              'Ucuz uçak bileti için en uygun zaman seyahatinizden 4-10 hafta öncesi diyebiliriz.',
          },
        ]}
      />
      <Layout>
        <Layout.Header router={router} withMobileNav={true} />
        <Layout.Container width="full">
          <HomePage>
            <HomePage.Search pt={[spt, spt, 4, pt, pt]}>
              <FlightSearchFormContainer
                initialValues={flightOffersSearchForm}
                fieldWidth={['100%', 1 / 2, 1 / 2, 1 / 3, 1 / 4]}
                hasBoxShadow={true}
                mode="important"
                variant="home-page"
                onSubmit={variables => {
                  Router.push(`/ucak-seferler?${qs.stringify(variables)}`);
                }}
              />
            </HomePage.Search>
            <HomePage.Companies />
            <HomePage.Travels />
            <HomePage.Articles
              cards={hotBlogs.map(item => {
                const hotBlog = item?.node;
                return {
                  title: hotBlog?.title?.[0].text,
                  description: hotBlog?.description?.[0].text,
                  href: `/content/blogs/${hotBlog?._meta?.uid}`,
                  imgSrc: hotBlog?.coverimage?.url,
                  imgAlt: hotBlog?.coverimage?.alt,
                };
              })}
            />
            <HomePage.AppDownload />
            <HomePage.Features />
            <HomePage.KeyWordsCards>
              <KeyWordsCards as="main" />
            </HomePage.KeyWordsCards>
          </HomePage>
        </Layout.Container>
        <Layout.Footer />
      </Layout>
    </>
  );
};

export async function getStaticProps(context) {
  const { default: lngDict = {} } = await import(
    `@biletiniz/intl/locales/${context.locale}.json`
  );
  const data = await useHotBlogs();

  return {
    props: { lng: context.locale, lngDict, hotBlogs: data.hotBlogs },
  };
}

export default Index;
